<template>
  <div>
    <!--section 1 -->
    <v-row no-gutters :class="$vuetify.breakpoint.smAndDown ? 'flex-column': ''">
      <v-col no-gutters 
        :cols="$vuetify.breakpoint.smAndDown ? '12': '6'"
        class="pb-4"
        :class="$vuetify.breakpoint.mdAndDown ? 'px-8': 'pl-32 pr-16'"   
        :style="
        `background-color: #FAFAFA;
        padding-top:${$vuetify.breakpoint.mdAndDown ? '80px' : '180px' }`"
        >
      <h1 
        :class="
        $vuetify.breakpoint.smAndDown ? 'mobile-heading' 
        :$vuetify.breakpoint.lgAndDown ?  'tab-heading' : 'heading1'"
        >
        Unlock Full IELTS Course Materials for Free with <br> 100+ Mock Tests & <br> Interactive Lessons Online
      </h1>
      <p class="mt-8">Looking for a free, high-quality IELTS preparation course online? Our IELTS course offers everything you need to achieve a high band score, including 70+ practice tests, interactive lessons, and full-length mock tests that simulate real exam conditions. Sign up today to get instant access to premium study materials and start learning today.
      </p>
      <v-btn
        v-if="!doesSessionExist"
        :class="$vuetify.breakpoint.lgAndDown ? 'mt-4': 'mt-8'"
        class="white--text text-btn text-xl"
        color="primary"
        depressed
        height="52px"
        width="206px"
        @click="$router.push({ path: '/auth/register' })"
        >
          <span class="text-h6">Enroll for Free</span> 
        </v-btn>
      </v-col>
      <v-col no-gutters 
        class="px-8"
        :cols="$vuetify.breakpoint.smAndDown ? '12': '6'" 
        :style="`padding-top:${$vuetify.breakpoint.smAndDown ? '50px' : $vuetify.breakpoint.lgAndDown ? '150px' : '107px' };`">
        <v-img
          :src="require('@/assets/ielts/banner1.webp')"
          >
        </v-img>
      </v-col>
    </v-row>

   <!--features and benefits-->
    <v-row no-gutters class="mt-16">
      <v-img
      no-gutters
      contain
      :src="require('@/assets/ielts/section2/background-sec2.png')"
      class="white--text"
      :class="$vuetify.breakpoint.mdAndDown ? 'py-16': 'py-10'"
      >
      <div :class="$vuetify.breakpoint.mdAndDown ? 'px-8': 'pl-32 pr-32'">
        <p class="font-weight-bold"
        :style="`font-size:${$vuetify.breakpoint.smAndDown? '26px': '40px' }`"
        >Features & Benefits</p>

        <v-row class="mt-9" :class="$vuetify.breakpoint.mdAndDown ? 'flex-column': ''">
            <v-col>
              <img
                  :src="require('@/assets/ielts/section2/1icon.svg')"
                  alt="image icon"
                />
              <p class="mt-sm-4 mt-lg-8 font-weight-bold"
              :style="`font-size:${$vuetify.breakpoint.smAndDown? '20px': '32px' }`"
              >30+ Interactive Lectures</p>
              <p class="mt-4">Gain access to expertly designed online lectures covering all IELTS sections: Reading, Writing, Speaking, and Listening.</p>
            </v-col>
            <v-col>
              <img
                  :src="require('@/assets/ielts/section2/2icon.svg')"
                  alt="image icon"
                />
              <p class="mt-sm-4 mt-lg-8 font-weight-bold"
              :style="`font-size:${$vuetify.breakpoint.smAndDown? '20px': '32px' }`"
              >50+ Question-Specific Practice Tests</p>
              <p class="mt-4">Improve your skills with IELTS-specific practice tests tailored to focus on key question types you’ll encounter on the real exam.</p>
            </v-col>
            <v-col>
              <img
                  :src="require('@/assets/ielts/section2/3icon.svg')"
                  alt="image icon"
                />
              <p class="mt-sm-4 mt-lg-8 font-weight-bold"
              :style="`font-size:${$vuetify.breakpoint.smAndDown? '20px': '32px' }`"
              >20+ Full-Length Mock Tests</p>
              <p class="mt-4">Prepare like a pro with over 20 free IELTS mock tests, simulating exam conditions to boost your confidence.</p>
            </v-col>
            <v-col>
              <img
                  :src="require('@/assets/ielts/section2/4icon.svg')"
                  alt="image icon"
                />
              <p class="mt-sm-4 mt-lg-8 font-weight-bold"
              :style="`font-size:${$vuetify.breakpoint.smAndDown? '20px': '32px' }`">Dynamic Lecture Slides</p>
              <p class="mt-4">Enhance your learning with detailed lecture slides that allow you to review and reinforce key concepts anytime.</p>
            </v-col>
          </v-row>
      </div>
      </v-img>
    </v-row>
    <!--ielts ad 1-->
    <v-row
        no-gutters 
        align="center" 
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column justify-center px-8 pt-18': 'pl-32 pr-32 pt-25'" >
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9" 
      class="font-weight-bold" 
      :style="`font-size:${$vuetify.breakpoint.smAndDown? '26px': '40px' };
      color: #2B4570
      `">
        Ace Your IELTS Exam with Our Free Online Course.<br>
        Sign Up Now for Full Access!
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3" class="d-flex justify-center">
        <!-- v-if="!doesSessionExist" -->
        <v-btn
            no-gutters
            elevation="0"
            class="white--text text-btn  py-8 px-12"
            color="primary"
            :style="`font-size:${$vuetify.breakpoint.mdAndDown ? '20px': '32px' };
             margin-top: ${$vuetify.breakpoint.smAndDown ? '16px': '0px' }
            `"
            @click="$router.push({ path: '/auth/register' })"
          >
          <div>
            Enroll now
          </div>
          </v-btn>
      </v-col>
    </v-row>
    <hr class="mx-8" :style="`margin-top:${$vuetify.breakpoint.smAndDown? '64px': '116px' }`">

    <!--course details-->
    <IeltsCourseDetails 
      id="course"
      class=" mb-8" 
      :class="$vuetify.breakpoint.mdAndDown ? 'px-8 pt-18': 'pl-32 pr-32 pt-25'"   />
    
      <!--why choose ielts-->
    <v-row
      no-gutters
      id="features"
      class="mt-16 d-flex pa-8"
      style="background-color: #FAFAFA; gap:20px"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column justify-center': ''"
      align="center"
    >
      <v-col no-gutters class="d-flex justify-center pl-sm-16">
        <v-img
            contain
            no-gutters
           :src="require('@/assets/ielts/section3/exam.webp')"
          :class="$vuetify.breakpoint.mdAndDown ? 'py-16': 'py-10'"
          />
      </v-col>
      <v-col max-width="816px">
          <P
          class="font-weight-bold"
           :style="`font-size:${$vuetify.breakpoint.mdAndDown? '26px': '40px' }`"
          >
            Why Choose Our IELTS Course?
        </P>
          <p
            class="pt-9"
          >
          Achieve a higher IELTS score with our IELTS preparation course online, tailored for students at all levels. Whether you’re aiming for a high band score or just starting out, our course provides free, high-quality materials including full-length IELTS mock tests, personalized feedback, and more!
          </p>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" class="d-flex flex-column align-start">
              <v-card
                color="#FAFAFA"
                v-for="(feature, index) in features"
                :key="index"
                class="d-flex justify-start align-center mt-8"
                flat
              >
                <v-icon>mdi-check-all</v-icon>
                <span class="pl-4">{{ feature }}</span>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters style="padding-top: 56px">
            <v-btn
              class="white--text"
              color="#0099dc"
              :to="{ path: '/auth/register' }"
            >
              Sign up
            </v-btn>
            <v-btn
              class="primary--text ml-4"
              outlined
              @click="$router.push('/ielts/courseDetails')"
            >
              View Full course
            </v-btn>
          </v-row> -->
        </v-col>
    </v-row>

    <!--faq-->
    <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'px-8 pt-18': 'pl-32 pr-32 pt-25'" >
      <v-col no-gutters class="ma-0 pa-0 d-flex justify-center">
        <v-card style="color: #000" flat  width="1385px">
          <h3
            class="text-h4 font-weight-bold pl-2 pb-6 d-flex justify-center"
            style="color: #2b4570"
          >
            FAQ
          </h3>
          <v-expansion-panels v-model="expandedModule" flat class="mt-sm-8 mt-md13">
            <v-expansion-panel
              v-for="(faq, index) in faqList"
              :key="index"
              class="my-2"
              active-class="panel-active"
            >
              <v-expansion-panel-header
                class="pa-0 ma-0 my-1"
                hide-actions
                disable-icon-rotate
              >
                <v-card flat color="#F4F4F4" class="py-5 px-4">
                  <div
                    class="d-flex align-center"
                    :class="classes.header"
                  >
                    <div class="d-flex flex-grow-1 clickable font-weight-bold">
                      {{ faq.ques }}
                    </div>
                    <v-btn icon>
                      <v-icon class="panel-arrow" size="25"
                        >{{
                          index != expandedModule ? "mdi-plus" : "mdi-close"
                        }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <div class="pt-8">
                    {{ faq.ans }}
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>

     <!--ielts ad 2-->
    <hr class="mx-8" :style="`margin-top:${$vuetify.breakpoint.mdAndDown? '64px': '116px' }`">
    <v-row 
        no-gutters
        align="center" 
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column px-8 pt-18': 'pl-32 pr-32 pt-25'">
      <v-col 
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8" 
        class="font-weight-bold" 
        :style="`font-size:${$vuetify.breakpoint.mdAndDown? '26px': '40px' }; color: #2B4570`">
        Ready to succeed in your IELTS exam? Get instant access to 100+ free practice tests and all the resources you need—sign up today!

      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4" class="d-flex justify-center">
        <v-btn
            no-gutters
            elevation="0"
            class="white--text text-btn  py-8 px-12"
            color="primary"
            :style="`font-size:${$vuetify.breakpoint.mdAndDown ? '20px': '32px' };
             margin-top: ${$vuetify.breakpoint.smAndDown ? '16px': '0px' }
            `"
            @click="$router.push({ path: '/auth/register' })"
          >
          <div>
            Enroll for Free
          </div>
          </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IeltsCourseDetails from './IeltsCourseDetails.vue';
export default {
  name: "IeltsHome",
components: {
    IeltsCourseDetails
},
  data() {
    return {
      features: [
        "Comprehensive Lectures covering Listening, Reading, Writing, and Speaking",
        "Step-by-Step Instructions for all IELTS question types",
        "Expert Tips for Academic Writing with structured advice on reports and essays for Writing Tasks 1 and 2",
        "Focused Vocabulary Lists for every proficiency level",
        "Pro Tips for Each Question Type to excel in each section of the exam",
      ],

      expandedModule: undefined,
      faqList : [
      {
        ques: "How can I access the free IELTS mock tests online?",
        ans: "Once you sign up for our free IELTS course, you’ll get immediate access to all mock tests and study materials."
      },
      {
        ques: "Is this course suitable for beginners?",
        ans: "Absolutely! Our IELTS preparation course is designed for students of all levels, offering a variety of practice tests and resources to help you prepare effectively."
      },
      {
        ques: "How long does it take to complete the course?",
        ans: "The course is self-paced, so you can complete it based on your schedule. Most students finish in 4-8 weeks depending on their commitment."
      },
      {
        ques: "Is the course focused on Academic or General IELTS?",
        ans: "Our IELTS course is designed to help with both the IELTS Academic and IELTS General Training exams."
      },
      {
        ques: "Can I retake the mock tests?",
        ans: "Yes, you can retake the IELTS mock tests as often as needed to improve your performance."
      },
      {
        ques: "Are the lectures live or pre-recorded?",
        ans: "All lectures are pre-recorded, so you can access them anytime that suits your schedule."
      },
      {
        ques: "Do I need to pay for access to any materials?",
        ans: "No, all course materials including lectures, mock tests, and practice questions are completely free."
      }
    ]
    };
  },
  computed: {
    doesSessionExist() {
      return this.$store.state.user.authToken !== undefined;
    },
    classes() {
      return {
        slideItem: {
          outer: {
            true: "primary white--text",
            false: "bg-white  black--text"
          },
          inner: {
            true: "white primary--text",
            false: "primary white--text"
          }
        },
        header: `px-${this.screenWidth < 600 ? 2 : 4}`
      };
    }
  },
  watch: {
    selectedSection(newVal) {
      if(newVal) {
        this.expandedModule = undefined
      }
    }
  },
};
</script>

<style lang="scss">

.pl-32 {
  padding-left: 130px !important;
}
.pr-32 {
  padding-right: 130px !important;
}
.pt-18 {
  padding-top: 70px !important;
}
.pt-25 {
  padding-top: 100px !important;
}
.v-image__image{
 background-size:100% 100%;
}
.transparent-background {
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the transparency as needed */
}
  .text-btn {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  }
.heading1 {
  color: #2b4570;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}
.mobile-heading {
  color: #2b4570;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
.tab-heading {
  color: #2b4570;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.growth-card {
  width: 364px;
  height: 230px;
  border-radius: 16px !important;
  box-shadow: 3px 4px 14px 0px rgba(255, 255, 255, 0.5) inset;
}

.growth-card-title {
  color: #323232;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.growth-card-content {
  color: #323232;
  font-family: Roboto;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.growth-card-subTitle {
  color: #5e4a4a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-input::placeholder {
  color: white;
  opacity: 0.5;
}

.email-input {
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 300px;
  color: white;
  padding-left: 16px;
}

.email-input:focus {
  color: white;
  border: 1px solid white;
}

#email-input:focus {
  outline: 1px solid white;
}
</style>
