var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "flex-column" : "",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "pb-4",
              class: _vm.$vuetify.breakpoint.mdAndDown ? "px-8" : "pl-32 pr-16",
              style: `background-color: #FAFAFA;
      padding-top:${_vm.$vuetify.breakpoint.mdAndDown ? "80px" : "180px"}`,
              attrs: {
                "no-gutters": "",
                cols: _vm.$vuetify.breakpoint.smAndDown ? "12" : "6",
              },
            },
            [
              _c(
                "h1",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "mobile-heading"
                    : _vm.$vuetify.breakpoint.lgAndDown
                    ? "tab-heading"
                    : "heading1",
                },
                [
                  _vm._v(" Unlock Full IELTS Course Materials for Free with "),
                  _c("br"),
                  _vm._v(" 100+ Mock Tests & "),
                  _c("br"),
                  _vm._v(" Interactive Lessons Online "),
                ]
              ),
              _c("p", { staticClass: "mt-8" }, [
                _vm._v(
                  "Looking for a free, high-quality IELTS preparation course online? Our IELTS course offers everything you need to achieve a high band score, including 70+ practice tests, interactive lessons, and full-length mock tests that simulate real exam conditions. Sign up today to get instant access to premium study materials and start learning today. "
                ),
              ]),
              !_vm.doesSessionExist
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text text-btn text-xl",
                      class: _vm.$vuetify.breakpoint.lgAndDown
                        ? "mt-4"
                        : "mt-8",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        height: "52px",
                        width: "206px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ path: "/auth/register" })
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "text-h6" }, [
                        _vm._v("Enroll for Free"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-8",
              style: `padding-top:${
                _vm.$vuetify.breakpoint.smAndDown
                  ? "50px"
                  : _vm.$vuetify.breakpoint.lgAndDown
                  ? "150px"
                  : "107px"
              };`,
              attrs: {
                "no-gutters": "",
                cols: _vm.$vuetify.breakpoint.smAndDown ? "12" : "6",
              },
            },
            [
              _c("v-img", {
                attrs: { src: require("@/assets/ielts/banner1.webp") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-16", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-img",
            {
              staticClass: "white--text",
              class: _vm.$vuetify.breakpoint.mdAndDown ? "py-16" : "py-10",
              attrs: {
                "no-gutters": "",
                contain: "",
                src: require("@/assets/ielts/section2/background-sec2.png"),
              },
            },
            [
              _c(
                "div",
                {
                  class: _vm.$vuetify.breakpoint.mdAndDown
                    ? "px-8"
                    : "pl-32 pr-32",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "font-weight-bold",
                      style: `font-size:${
                        _vm.$vuetify.breakpoint.smAndDown ? "26px" : "40px"
                      }`,
                    },
                    [_vm._v("Features & Benefits")]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-9",
                      class: _vm.$vuetify.breakpoint.mdAndDown
                        ? "flex-column"
                        : "",
                    },
                    [
                      _c("v-col", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/ielts/section2/1icon.svg"),
                            alt: "image icon",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "mt-sm-4 mt-lg-8 font-weight-bold",
                            style: `font-size:${
                              _vm.$vuetify.breakpoint.smAndDown
                                ? "20px"
                                : "32px"
                            }`,
                          },
                          [_vm._v("30+ Interactive Lectures")]
                        ),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Gain access to expertly designed online lectures covering all IELTS sections: Reading, Writing, Speaking, and Listening."
                          ),
                        ]),
                      ]),
                      _c("v-col", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/ielts/section2/2icon.svg"),
                            alt: "image icon",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "mt-sm-4 mt-lg-8 font-weight-bold",
                            style: `font-size:${
                              _vm.$vuetify.breakpoint.smAndDown
                                ? "20px"
                                : "32px"
                            }`,
                          },
                          [_vm._v("50+ Question-Specific Practice Tests")]
                        ),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Improve your skills with IELTS-specific practice tests tailored to focus on key question types you’ll encounter on the real exam."
                          ),
                        ]),
                      ]),
                      _c("v-col", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/ielts/section2/3icon.svg"),
                            alt: "image icon",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "mt-sm-4 mt-lg-8 font-weight-bold",
                            style: `font-size:${
                              _vm.$vuetify.breakpoint.smAndDown
                                ? "20px"
                                : "32px"
                            }`,
                          },
                          [_vm._v("20+ Full-Length Mock Tests")]
                        ),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Prepare like a pro with over 20 free IELTS mock tests, simulating exam conditions to boost your confidence."
                          ),
                        ]),
                      ]),
                      _c("v-col", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/ielts/section2/4icon.svg"),
                            alt: "image icon",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "mt-sm-4 mt-lg-8 font-weight-bold",
                            style: `font-size:${
                              _vm.$vuetify.breakpoint.smAndDown
                                ? "20px"
                                : "32px"
                            }`,
                          },
                          [_vm._v("Dynamic Lecture Slides")]
                        ),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Enhance your learning with detailed lecture slides that allow you to review and reinforce key concepts anytime."
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown
            ? "flex-column justify-center px-8 pt-18"
            : "pl-32 pr-32 pt-25",
          attrs: { "no-gutters": "", align: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "font-weight-bold",
              style: `font-size:${
                _vm.$vuetify.breakpoint.smAndDown ? "26px" : "40px"
              };
    color: #2B4570
    `,
              attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 9 },
            },
            [
              _vm._v(" Ace Your IELTS Exam with Our Free Online Course."),
              _c("br"),
              _vm._v(" Sign Up Now for Full Access! "),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center",
              attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 3 },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text text-btn py-8 px-12",
                  style: `font-size:${
                    _vm.$vuetify.breakpoint.mdAndDown ? "20px" : "32px"
                  };
           margin-top: ${_vm.$vuetify.breakpoint.smAndDown ? "16px" : "0px"}
          `,
                  attrs: { "no-gutters": "", elevation: "0", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ path: "/auth/register" })
                    },
                  },
                },
                [_c("div", [_vm._v(" Enroll now ")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", {
        staticClass: "mx-8",
        style: `margin-top:${
          _vm.$vuetify.breakpoint.smAndDown ? "64px" : "116px"
        }`,
      }),
      _c("IeltsCourseDetails", {
        staticClass: "mb-8",
        class: _vm.$vuetify.breakpoint.mdAndDown
          ? "px-8 pt-18"
          : "pl-32 pr-32 pt-25",
        attrs: { id: "course" },
      }),
      _c(
        "v-row",
        {
          staticClass: "mt-16 d-flex pa-8",
          class: _vm.$vuetify.breakpoint.smAndDown
            ? "flex-column justify-center"
            : "",
          staticStyle: { "background-color": "#FAFAFA", gap: "20px" },
          attrs: { "no-gutters": "", id: "features", align: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center pl-sm-16",
              attrs: { "no-gutters": "" },
            },
            [
              _c("v-img", {
                class: _vm.$vuetify.breakpoint.mdAndDown ? "py-16" : "py-10",
                attrs: {
                  contain: "",
                  "no-gutters": "",
                  src: require("@/assets/ielts/section3/exam.webp"),
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { "max-width": "816px" } },
            [
              _c(
                "P",
                {
                  staticClass: "font-weight-bold",
                  style: `font-size:${
                    _vm.$vuetify.breakpoint.mdAndDown ? "26px" : "40px"
                  }`,
                },
                [_vm._v(" Why Choose Our IELTS Course? ")]
              ),
              _c("p", { staticClass: "pt-9" }, [
                _vm._v(
                  " Achieve a higher IELTS score with our IELTS preparation course online, tailored for students at all levels. Whether you’re aiming for a high band score or just starting out, our course provides free, high-quality materials including full-length IELTS mock tests, personalized feedback, and more! "
                ),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column align-start",
                      attrs: { cols: "12" },
                    },
                    _vm._l(_vm.features, function (feature, index) {
                      return _c(
                        "v-card",
                        {
                          key: index,
                          staticClass: "d-flex justify-start align-center mt-8",
                          attrs: { color: "#FAFAFA", flat: "" },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-check-all")]),
                          _c("span", { staticClass: "pl-4" }, [
                            _vm._v(_vm._s(feature)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndDown
            ? "px-8 pt-18"
            : "pl-32 pr-32 pt-25",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "ma-0 pa-0 d-flex justify-center",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: { color: "#000" },
                  attrs: { flat: "", width: "1385px" },
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-h4 font-weight-bold pl-2 pb-6 d-flex justify-center",
                      staticStyle: { color: "#2b4570" },
                    },
                    [_vm._v(" FAQ ")]
                  ),
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "mt-sm-8 mt-md13",
                      attrs: { flat: "" },
                      model: {
                        value: _vm.expandedModule,
                        callback: function ($$v) {
                          _vm.expandedModule = $$v
                        },
                        expression: "expandedModule",
                      },
                    },
                    _vm._l(_vm.faqList, function (faq, index) {
                      return _c(
                        "v-expansion-panel",
                        {
                          key: index,
                          staticClass: "my-2",
                          attrs: { "active-class": "panel-active" },
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass: "pa-0 ma-0 my-1",
                              attrs: {
                                "hide-actions": "",
                                "disable-icon-rotate": "",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "py-5 px-4",
                                  attrs: { flat: "", color: "#F4F4F4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-center",
                                      class: _vm.classes.header,
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-grow-1 clickable font-weight-bold",
                                        },
                                        [_vm._v(" " + _vm._s(faq.ques) + " ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "panel-arrow",
                                              attrs: { size: "25" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  index != _vm.expandedModule
                                                    ? "mdi-plus"
                                                    : "mdi-close"
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-expansion-panel-content", [
                            _c("div", { staticClass: "pt-8" }, [
                              _vm._v(" " + _vm._s(faq.ans) + " "),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", {
        staticClass: "mx-8",
        style: `margin-top:${
          _vm.$vuetify.breakpoint.mdAndDown ? "64px" : "116px"
        }`,
      }),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown
            ? "flex-column px-8 pt-18"
            : "pl-32 pr-32 pt-25",
          attrs: { "no-gutters": "", align: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "font-weight-bold",
              style: `font-size:${
                _vm.$vuetify.breakpoint.mdAndDown ? "26px" : "40px"
              }; color: #2B4570`,
              attrs: { cols: _vm.$vuetify.breakpoint.mdAndDown ? 12 : 8 },
            },
            [
              _vm._v(
                " Ready to succeed in your IELTS exam? Get instant access to 100+ free practice tests and all the resources you need—sign up today! "
              ),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center",
              attrs: { cols: _vm.$vuetify.breakpoint.mdAndDown ? 12 : 4 },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text text-btn py-8 px-12",
                  style: `font-size:${
                    _vm.$vuetify.breakpoint.mdAndDown ? "20px" : "32px"
                  };
           margin-top: ${_vm.$vuetify.breakpoint.smAndDown ? "16px" : "0px"}
          `,
                  attrs: { "no-gutters": "", elevation: "0", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ path: "/auth/register" })
                    },
                  },
                },
                [_c("div", [_vm._v(" Enroll for Free ")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }